<template>
  <div>
    <b-form-group
      label="Nombres:"
      label-for="first_name-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0 mb-2"
    >
      <b-form-input
        id="first_name-input"
        name="first_name-input"
        type="text"
        v-model="$v.user.first_name.$model"
        :state="validateState('first_name')"
        :disabled="!allows_crud"
        aria-describedby="input-first_name-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="input-first_name-live-feedback"
        >Este campo es obligatorio y debe tener al menos 3
        caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Apellidos:"
      label-for="last_name-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0 mb-2"
    >
      <b-form-input
        id="last_name-input"
        name="last_name-input"
        type="text"
        :disabled="!allows_crud"
        v-model="$v.user.last_name.$model"
        :state="validateState('last_name')"
        aria-describedby="input-last_name-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="input-last_name-live-feedback"
        >Este campo es obligatorio y debe tener al menos 3
        caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Tipo de Identificador:"
      label-for="identifier_type-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0 mb-2"
    >
      <b-form-select
        id="select-identifier_type"
        name="select-identifier_type"
        :options="IdentifierTypes"
        value-field="id"
        text-field="identifier_name"
        :disabled="!allows_crud"
        v-model="$v.user_data.identifier_type.$model"
        :state="validateStateUserData('identifier_type')"
        aria-describedby="select-identifier_type-live-feedback"
        size="md"
      >
      </b-form-select>
      <b-form-invalid-feedback id="select-region-live-feedback"
        >Debe seleccionar tipo de identificador.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Identificador:"
      label-for="identifier-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0 mb-2"
    >
      <b-form-input
        id="identifier-input"
        name="identifier-input"
        type="text"
        v-model="$v.user_data.identifier.$model"
        :disabled="!allows_crud"
        :state="validateStateUserData('identifier')"
        aria-describedby="input-identifier-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="input-identifier-live-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Correo:"
      label-for="email-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <div class="d-flex">
        <div class="d-blok w-100 align-self-end">
          <b-form-input
            id="email-input"
            name="email-input"
            type="text"
            v-model="$v.user.email.$model"
            :disabled="!allows_crud || !isNaN(user.id)"
            @update="
              (value) => {
                user.email = value.toLowerCase();
              }
            "
            @input="
              () => {
                verified_email = false;
                msg_email_error =
                  'Verifique que el correo este disponible en la plataforma.';
              }
            "
            :state="validateState('email')"
            aria-describedby="input-email-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-email-live-feedback">
            <div v-if="user.email == '' || user.email == null">
              Este campo es obligatorio y debe tener al menos 3 caracteres.
            </div>
            <div v-if="!verified_email">
              {{ msg_email_error }}
            </div>
            <div
              v-if="
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                  user.email
                )
              "
            >
              El correo no tiene un formato válido.
            </div>
          </b-form-invalid-feedback>
        </div>
        <b-button
          v-if="allows_crud && isNaN(user.id)"
          @click="checkEmail()"
          class="password-visibility"
          v-b-tooltip.v-secondary.noninteractive="
            `${
              !verified_email
                ? 'Verifique que el correo este disponible en la plataforma.'
                : 'Correo disponible'
            }`
          "
          ><b-icon v-if="verified_email" icon="check"></b-icon
          ><b-icon v-else icon="info-circle"></b-icon
        ></b-button>
      </div>
    </b-form-group>
    <b-form-group
      v-if="isNaN(user.id)"
      id="input-group-password"
      label="Contraseña"
      label-for="input-password"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0 mb-2"
    >
      <div class="d-flex">
        <div class="d-blok w-100 align-self-end">
          <b-form-input
            id="input-password"
            v-model="$v.user.password.$model"
            :type="!password_visibility ? 'password' : 'text'"
            :state="validateState('password')"
            placeholder="Contraseña"
            @keyup.enter="changePassword"
            :disabled="!allows_crud"
            :class="{ 'my-2': !password_visibility }"
            aria-describedby="input-password-name-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-password-name-feedback"
            ><div class="d-block text-left">
              La contraseña de estar compuesta al menos por:
              <div v-if="!/[a-z]/.test(user.password)" class="ml-4">
                - Al menos una letra en minúsculas.
              </div>
              <div v-if="!/[A-Z]/.test(user.password)" class="ml-4">
                - Al menos una letra en mayúsculas.
              </div>
              <div v-if="!/[0-9]/.test(user.password)" class="ml-4">
                - Al menos un número.
              </div>
              <div v-if="!/[.#?!@$%^&*-]/.test(user.password)" class="ml-4">
                - Al menos un simbolo.
              </div>
              <div v-if="8 > user.password.length" class="ml-4">
                - Debe tener 8 carácteres como mínimo.
              </div>
            </div></b-form-invalid-feedback
          >
        </div>
        <b-button
          class="password-visibility"
          @click="() => (password_visibility = !password_visibility)"
          ><b-icon v-if="password_visibility" icon="eye"></b-icon
          ><b-icon v-else icon="eye-slash"></b-icon
        ></b-button>
      </div>
    </b-form-group>
    <b-form-group
      label="Rol:"
      label-for="role-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0 mb-2"
    >
      <b-form-select
        id="select-role"
        name="select-role"
        :options="RolesFiltered"
        value-field="id"
        text-field="position_name"
        :disabled="!allows_crud || this.user.is_superuser"
        @change="changeRol"
        v-model="$v.access.role.$model"
        :state="validateStateAccess('role')"
        aria-describedby="select-role-live-feedback"
        size="md"
      >
      </b-form-select>
      <b-form-invalid-feedback id="select-region-live-feedback"
        >Debe seleccionar un rol.</b-form-invalid-feedback
      >
    </b-form-group>
    <template v-if="isRoleStudent">
      <b-form-group
        :label="
          $getVisibleNames('mesh.egressprofile', false, 'Perfil de Egreso') +
          ':'
        "
        label-for="egress_profile-input"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0 mb-2"
      >
        <!-- <b-form-select
          id="select-egress_profile"
          name="select-egress_profile"
          :options="egressProfiles"
          value-field="id"
          text-field="name"
          :disabled="!allows_crud"
          v-model="$v.student_card.egress_profile.$model"
          :state="validateStateStudentCard('egress_profile')"
          aria-describedby="select-egress_profile-live-feedback"
          size="md"
        >
        </b-form-select> -->

        <v-select
          id="select-egress_profile"
          name="select-egress_profile"
          v-model="$v.student_card.egress_profile.$model"
          :options="egressProfiles"
          :reduce="(egressProfiles) => egressProfiles.id"
          :placeholder="`Seleccione un ${$getVisibleNames(
            'mesh.egressprofile',
            false,
            'Perfil de Egreso'
          )}`"
          :dropdown-should-open="dropdownShouldOpen"
          label="name"
          track-by="id"
          :disabled="!allows_crud"
          :state="validateStateStudentCard('egress_profile')"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
        <div
          class="div-invalid-feedback"
          v-if="$v.student_card.egress_profile.$anyError == true"
        >
          Debe seleccionar un
          {{
            $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso")
          }}.
        </div>
      </b-form-group>
      <b-form-group
        label="Cohorte:"
        label-for="cohort-input"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0 mb-2"
      >
        <b-form-input
          id="cohort-input"
          name="cohort-input"
          type="number"
          v-model="$v.student_card.cohort.$model"
          :disabled="!allows_crud"
          :state="validateStateStudentCard('cohort')"
          aria-describedby="input-cohort-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-cohort-live-feedback"
          >Este campo es obligatorio y debe colocar un año
          válido</b-form-invalid-feedback
        >
      </b-form-group>
    </template>
    <!-- <b-form-group
      label="Género:"
      label-for="gender-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-gender"
        name="select-gender"
        :options="genders"
        value-field="value"
        text-field="text"
        :disabled="!allows_crud"
        v-model="$v.user.gender.$model"
        :state="validateState('gender')"
        aria-describedby="select-gender-live-feedback"
        size="md"
      >
      </b-form-select>
      <b-form-invalid-feedback id="select-region-live-feedback"
        >Debe seleccionar Género.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <!-- <b-form-group
      label="Fecha de Nacimiento:"
      label-for="last_name-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 mt-3 p-0"
    >
      <b-form-datepicker
        id="input-birth_date"
        v-model="$v.user.birth_date.$model"
        :disabled="!allows_crud"
        :state="validateState('birth_date')"
        placeholder="Definir fecha de Nacimiento"
        aria-describedby="input-birth_date-feedback"
        size="sm"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-birth_date-feedback"
        >Debe ingresar una Fecha de Nacimiento válida.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <template v-if="!isRoleStudent && !isRoleProfesor">
      <b-form-group label-cols="0" label-cols-sm="3" class="sm">
        <template #label>
          Notificaciones de la biblioteca
          <InfoTooltip
            class="info-icon"
            :tooltip_text="`Si está seleccionado, recibirás notificaciones sobre cambios en el
          catálogo o nuevas adquisiciones.`"
          >
          </InfoTooltip>
        </template>
        <b-form-checkbox
          class="mt-2"
          v-model="user_data.alert_bibliography_change"
          :disabled="!allows_crud"
        >
        </b-form-checkbox
      ></b-form-group>
    </template>
    <div v-if="allows_crud" class="col mt-3" style="text-align: right">
      <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { toast } from "@/utils/utils";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "UserForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    User: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          is_active: true,
          is_staff: false,
          is_superuser: false,
          gender: 2,
          birth_date: null,
          school: null,
        };
      },
    },
    UserData: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          user: null,
          identifier_type: null,
          address: null,
          identifier: "",
          phone_1: "",
          phone_2: "",
          alternative_email: "",
          alert_bibliography_change: false,
          image: null,
          school: null,
        };
      },
    },
    Access: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          user: null,
          role: null,
          school: null,
        };
      },
    },
    StudentCard: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          user: null,
          school: null,
          egress_profile: null,
          cohort: null,
          admission_profile: null,
          campus: null,
          career: null,
          faculty: null,
          shift: null,
        };
      },
    },
    // Address: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       id: generateUniqueId(),
    //       line_1: "",
    //       line_2: "",
    //       zip_code: "",
    //       commune: null,
    //     };
    //   },
    // },
    IdentifierTypes: {
      type: Array,
    },
    Roles: {
      type: Array,
    },
    allows_crud: {
      type: Boolean,
      required,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    // show_delete_button: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      msg_email_error:
        "Verifique que el correo este disponible en la plataforma.",
      user: { ...this.User },
      user_data: { ...this.UserData },
      access: { ...this.Access },
      student_card: { ...this.StudentCard },
      //   address: { ...this.Address },
      password_visibility: true,
      verified_email: !isNaN(this.User.id) ? true : false,
      // genders: [
      //   { value: 0, text: "Masculino" },
      //   { value: 1, text: "Femenino" },
      //   { value: 2, text: "Otro" },
      // ],
    };
  },
  validations: {
    user: {
      first_name: { required, minLength: minLength(3) },
      last_name: { required, minLength: minLength(3) },
      email: {
        required,
        isValid() {
          if (
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
              this.user.email
            ) &&
            this.verified_email
          ) {
            return true;
          } else return false;
        },
      },
      password: {
        isValid() {
          if (isNaN(this.user.id))
            if (
              !/[a-z]/.test(this.user.password) ||
              !/[A-Z]/.test(this.user.password) ||
              !/[0-9]/.test(this.user.password) ||
              !/[.#?!@$%^&*-]/.test(this.user.password) ||
              this.user.password.length < 8 ||
              this.user.password == "" ||
              this.user.password == null
            )
              return false;
            else return true;
          else return true;
        },
      },
    },
    user_data: {
      user: {},
      identifier_type: { required },
      identifier: { required },
      phone_1: {},
      phone_2: {},
      alternative_email: {},
    },
    access: {
      user: {},
      role: { required },
    },
    student_card: {
      egress_profile: {
        isValid() {
          if (this.isRoleStudent && this.student_card.egress_profile == null)
            return false;
          else return true;
        },
      },
      cohort: {
        isValid() {
          if (
            this.isRoleStudent &&
            (this.student_card.cohort == null ||
              this.student_card.cohort == "" ||
              String(this.student_card.cohort).length != 4)
          )
            return false;
          else return true;
        },
      },
    },
    validationGroup: ["user", "user_data", "access", "student_card"],
  },
  computed: {
    ...mapGetters({
      CustomUser: "getUser",
      institution: "getInstitution",
      egressProfiles: names.EGRESS_PROFILES,
    }),
    RolesFiltered() {
      const roles = this.Roles;
      return roles.sort((a, b) => a.position - b.position);
    },
    isRoleStudent() {
      if (this.access.role == null) return false;
      const role = this.Roles.find((x) => x.id == this.access.role);
      if (role) return role.position === 7 ? true : false;
      else return false;
    },
    isRoleProfesor() {
      if (this.access.role == null) return false;
      const role = this.Roles.find((x) => x.id == this.access.role);
      if (role) return role.position === 6 ? true : false;
      else return false;
    },
  },
  methods: {
    changeRol(value) {
      const role = this.RolesFiltered.find((x) => x.id == value);
      if (role) {
        if ([6, 7].includes(role.position))
          this.user_data.alert_bibliography_change = false;
      }
    },
    dropdownShouldOpen(VueSelect) {
      if (this.egressProfiles.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    validateState(key) {
      const { $dirty, $error } = this.$v.user[key];
      return $dirty ? !$error : null;
    },
    checkEmail() {
      if (
        this.user.email.trim() != "" &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          this.user.email
        )
      )
        this.$restful
          .Get(
            `/authentication/check_if_email_exists/?email=${this.user.email}`
          )
          .then((response) => {
            if (response == false) this.verified_email = true;
            else if (response)
              if (
                response.id == this.user.id &&
                response.email == this.user.email
              )
                this.verified_email = true;
              else {
                this.msg_email_error =
                  "Este correo no está disponible para su uso.";
                // toast("Este correo no está disponible para su uso.");
                this.verified_email = false;
              }
          });
    },
    validateStateUserData(key) {
      const { $dirty, $error } = this.$v.user_data[key];
      return $dirty ? !$error : null;
    },
    validateStateAccess(key) {
      const { $dirty, $error } = this.$v.access[key];
      return $dirty ? !$error : null;
    },
    validateStateStudentCard(key) {
      const { $dirty, $error } = this.$v.student_card[key];
      return $dirty ? !$error : null;
    },
    save() {
      this.user.school = this.institution.id;
      this.access.school = this.institution.id;
      this.student_card.school = this.institution.id;
      this.user_data.school = this.institution.id;
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      if (isNaN(this.user.id)) this.createUser();
      else this.updateUser();
    },
    createUser() {
      this.$store.dispatch(names.POST_USER, this.user).then((response) => {
        this.user = response;
        this.user_data.user = response.id;
        this.access.user = response.id;
        this.student_card.user = response.id;
        if (isNaN(this.user_data.id)) this.createdUserData();
        else this.updateUserData();
        toast("Usuario creado.");
        this.$emit("created", response);
      });
    },
    updateUser() {
      this.$store
        .dispatch(names.PATCH_USER, {
          user_id: this.user.id,
          item: {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            email: this.user.email,
            // gender: this.user.gender,
            // birth_date: this.user.birth_date,
          },
        })
        .then((response) => {
          this.user = response;
          this.user_data.user = response.id;
          this.access.user = response.id;
          this.student_card.user = response.id;
          if (isNaN(this.user_data.id)) this.createdUserData();
          else this.updateUserData();
          toast("Usuario Actualizado.");
          this.$emit("updated", response);
        });
    },
    createdUserData() {
      this.$restful
        .Post(`/authentication/user-data/`, this.user_data)
        .then((response) => {
          this.user_data = response;
          if (isNaN(this.access.id)) this.createdAccess();
          else this.updateAccess();
          this.$emit("created_user_data", response);
        });
    },
    updateUserData() {
      this.$restful
        .Put(`/authentication/user-data/${this.user_data.id}/`, this.user_data)
        .then((response) => {
          this.user_data = response;
          if (isNaN(this.access.id)) this.createdAccess();
          else this.updateAccess();
          this.$emit("updated_user_data", response);
        });
    },
    createdAccess() {
      this.$restful.Post(`/teaching/access/`, this.access).then((response) => {
        this.access = response;
        if (response.position == 7) {
          if (isNaN(this.student_card.id)) this.createdStudentCard();
          else this.updateStudentCard();
        } else
          this.$store
            .dispatch(names.FETCH_USER, this.user.id)
            .then((response_user) => {
              if (response_user.id == this.CustomUser.id)
                this.$store.commit("setUser", response_user);
            });
        this.$emit("created_access", response);
      });
    },
    updateAccess() {
      this.$restful
        .Patch(`/teaching/access/${this.access.id}/`, this.access)
        .then((response) => {
          this.access = response;
          if (response.position == 7) {
            if (isNaN(this.student_card.id)) this.createdStudentCard();
            else this.updateStudentCard();
          } else
            this.$store
              .dispatch(names.FETCH_USER, this.user.id)
              .then((response_user) => {
                if (response_user.id == this.CustomUser.id)
                  this.$store.commit("setUser", response_user);
              });
          this.$emit("updated_access", response);
        });
    },
    createdStudentCard() {
      this.$restful
        .Post(`/cideu/student-card/`, this.student_card)
        .then((response) => {
          this.student_card = response;
          this.$store
            .dispatch(names.FETCH_USER, this.user.id)
            .then((response_user) => {
              if (response_user.id == this.CustomUser.id)
                this.$store.commit("setUser", response_user);
            });
          this.$emit("created_student_card", response);
        });
    },
    updateStudentCard() {
      this.$restful
        .Put(`/cideu/student-card/${this.student_card.id}/`, this.student_card)
        .then((response) => {
          this.student_card = response;
          this.$store
            .dispatch(names.FETCH_USER, this.user.id)
            .then((response_user) => {
              if (response_user.id == this.CustomUser.id)
                this.$store.commit("setUser", response_user);
            });
          this.$emit("updated_student_card", response);
        });
    },
  },
  mounted() {},
  created() {
    if (!isNaN(this.user.id)) {
      this.$restful
        .Get(`/authentication/user-data/?user=${this.user.id}`)
        .then((response) => {
          if (response.length > 0) this.user_data = response[0];
        });
      this.$restful
        .Get(`/teaching/access/?user=${this.user.id}`)
        .then((response) => {
          if (response.length > 0) this.access = response[0];
        });
      this.$restful
        .Get(`/cideu/student-card/?user=${this.user.id}`)
        .then((response) => {
          if (response.length > 0) this.student_card = response[0];
        });
    }
  },
};
</script>

<style scoped>
.password-visibility {
  height: 2.35em;
  margin-top: 8px;
  margin-left: 1%;
}
.div-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>